import React from "react"
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import BackgroundImg from 'gatsby-background-image'
import Typography from '@material-ui/core/Typography';
import { Link } from 'gatsby'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import teal from '@material-ui/core/colors/teal'

const useStyles = makeStyles(theme =>({
    root: {
        padding: '0 30px',
    },
    hero: {
        minHeight: '80vh',
        padding: '15px',
        backgroundColor: 'rgba(0,0,0,.3)',
    },
    wrapper: {
        minHeight: '80vh',
        padding: '25px',
        // backgroundColor: 'rgba(0,0,0,.3)',
        color: '#ffffff',
        borderColor: 'rgba(255,255,255,.5)',
        borderWidth: '1px',
        borderStyle: 'solid',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
    },
    heading2: {
        position: 'relative',
        '&::before': {
            content: '""',
            display: 'block',
            backgroundColor: '#ffffff',
            width: '5rem',
            height: '.15rem',
            marginLeft: 'auto',
            marginRight: 'auto',
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: '-2rem',
        }
    },
    paragraphs: {
        marginBottom: '1rem',
        marginTop: '2rem',
    },
    paragraph: {
        marginBottom: '.5rem',
    },
    span: {

    },
}));

const HeroBox = ({ descriptions, stationName, image, language }) => {
    const classes = useStyles();
    // const primary = teal[600];
    // const station = nodes.filter(function(node, index) {
    //     if (node.name === stationName) {
    //     return node;
    //     }
    // });
    // let linkto = '';
    // let label = '';
    // let title = '';
    // let descriptions = [];
    // if ('ja' === language) {
    //     linkto = '/station/'+stationName+'/';
    //     label = station[0].pages.ja.stationName+'詳細';
    //     title = station[0].pages.ja.stationName;
    //     descriptions = station[0].pages.ja.descriptions;
    // } else if ('en' === language) {
    //     linkto = '/en/station/'+stationName+'/';
    //     label = 'Detail of '+station[0].pages.en.stationName;
    //     title = station[0].pages.en.stationName;
    //     descriptions = station[0].pages.en.descriptions;
    // } else if ('zh_hant' === language) {
    //     linkto = '/zh_hant/station/'+stationName+'/';
    //     label = station[0].pages.zh_hant.stationName+'詳細';
    //     title = station[0].pages.zh_hant.stationName;
    //     descriptions = station[0].pages.zh_hant.descriptions;
    // } else if ('zh_hans' === language) {
    //     linkto = '/zh_hans/station/'+stationName+'/';
    //     label = station[0].pages.zh_hans.stationName+'詳細';
    //     title = station[0].pages.zh_hans.stationName;
    //     descriptions = station[0].pages.zh_hans.descriptions;
    // } else {
    //     linkto = '/station/'+stationName+'/';
    //     label = station[0].pages.ja.stationName+'詳細';
    //     title = station[0].pages.ja.stationName;
    //     descriptions = station[0].pages.ja.descriptions;
    // }

    return (
        <BackgroundImg
            Tag="section"
            fluid={image}
            backgourndColor={`#48bd98`}
            style={{
                minHeight: '80vh',
                backgroundSize: 'auto',
            }}
        >
            <section className={classes.hero}><div className={classes.wrapper}>
                <h2><span className={classes.heading2}>{stationName}</span></h2>
                <div className={classes.paragraphs}>
                    {
                        descriptions.map((description, i) => (
                            <p className={classes.paragraph} key={i}><span className={classes.span}>{description}</span></p>
                        ))
                    }
                </div>
                {/* <Chip
                    avatar={<Avatar><ChevronRightIcon /></Avatar>}
                    label={label}
                    clickable
                    color="primary"
                    variant="outlined"
                /> */}
            </div></section>
        </BackgroundImg>
        // <pre>{JSON.stringify(image, null, 4)}</pre>
    );
}

HeroBox.propTypes = {
    descriptions: PropTypes.array,
    stationName: PropTypes.string,
    image: PropTypes.object,
    language: PropTypes.string,
}

export default HeroBox