import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
    root: {

    },
    heading2: {
        // color: '#48bd98',
        marginTop: '5rem',
        '&::before': {
            content: '""',
            display: 'inline-block',
            position: 'relative',
            top: '-.1em',
            verticalAlign: 'middle',
            height: '1em',
            borderLeftColor: '#48bd98',
            borderLeftWidth: '7px',
            borderLeftStyle: 'solid',
            marginRight: '10px',
        }
    },
    heading22: {
        margin: '5rem 0 2rem',
        paddingLeft: '1rem',
        position: 'relative',
        // fontSize: '1.6rem',
        // lineHeight: '1.4',
        '&::before': {
            content: '""',
            display: 'block',
            backgroundColor: '#48bd96',
            width: '7px',
            // height: '100%',
            // borderRadius: '10px',
            // padding: '.2rem 0',
            position: 'absolute',
            left: 0,
            top: '-5px',
            bottom: '-5px',
        },
    },
    heading3: {
        marginLeft: '15px',
        marginTop: '1.45rem',
        borderBottomColor: '#cccccc',
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        paddingBottom: '5px',
        fontWeight: 'normal',
        '&::after': {
            content: '""',
            display: 'block',
            borderBottomColor: '#48bd98',
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid',
            width: '25%',
            position: 'relative',
            bottom: '-6px',
        }
    },
}));

const Heading = ({title, isChild}) => {
    const classes = useStyles();
    if (title !== null && title !== '') {
        if (!isChild) {
            return (
                <h2 className={classes.heading22}>{title}</h2>
                
            )
        } else {
            return (
                <h3 className={classes.heading3}>{title}</h3>
            )
        }
    } else {
        return null
    }
}

Heading.propTypes = {
    title: PropTypes.string,
    isChild: PropTypes.bool,
}

export default Heading