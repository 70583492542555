import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'

import HeaderGE from './header/header'
import HeaderJA from './header/ja'
import HeaderEN from './header/en'
import HeaderTW from './header/zh_hant'
import HeaderCN from './header/zh_hans'

const Header = ({siteTitle, language, slug}) => {
    if ('ja' === language) {
        return <HeaderJA siteTitle={siteTitle} language={language} slug={slug} />
    } else if ('en' === language) {
        return <HeaderEN siteTitle={siteTitle} language={language} slug={slug}  />        
    } else if ('zh_hant' === language) {
        return <HeaderTW siteTitle={siteTitle} language={language} slug={slug}  />
    } else if ('zh_hans' === language) {
        return <HeaderCN siteTitle={siteTitle} language={language} slug={slug}  />
    } else {
        return <HeaderGE siteTitle={siteTitle} language={language} slug={slug}  />
    }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  language: PropTypes.string,
  slug: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header



