import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import { Menu } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import Slide from '@material-ui/core/Slide'
import Fab from '@material-ui/core/Fab'
import Zoom from '@material-ui/core/Zoom'

import MenuIcon from '@material-ui/icons/Menu'
import TrainIcon from '@material-ui/icons/Train'
import LanguageIcon from '@material-ui/icons/Language'
import TranslateIcon from '@material-ui/icons/Translate'
import HomeIcon from '@material-ui/icons/Home'
import ListIcon from '@material-ui/icons/ViewList'
import MoreIcon from '@material-ui/icons/MoreVert'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

import logo from '../../assets/jruro.png'

/** Scroll to Hide AppBar */
function HideOnScroll(props) {
  const {children, window} = props
  const trigger = useScrollTrigger({target: window ? window() : undefined})

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

/** Back to Top */
function ScrollTop(props) {
  const { children, window } = props
  const classes = useStyles()
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  })

  const handleClick = event => {
    const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');

    if (anchor) {
      console.log(anchor)
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
      console.log('Scroll Up')
    }
  }

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.scrollUp}>
        {children}
      </div>
    </Zoom>
  )
}

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  scrollUp: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  logo: {
    marginBottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // background: 'linear-gradient(to right,  #48bd98, #48bd98)',
    background: '#48bd98',
    // background: 'theme.palette.primary.main'
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}))

const Header = ({ siteTitle }, {props}) => {
  const classes = useStyles()

  const theme = useTheme()
  const [open, setOpen] = React.useState(false)

  function handleDrawerOpen() {
    setOpen(true)
  }

  function handleDrawerClose() {
    setOpen(false)
  }

  /** More Menu on AppBar */
  const [auth, setAuth] = React.useState(true)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const openMenu = Boolean(anchorEl)

  const handleChange = event => {
    setAuth(event.target.checked)
  }

  const handleMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }


  return (
    <div id="back-to-top-anchor" className={classes.root}>
      {/* <CssBaseline /> */}
      <HideOnScroll {...props}>
      <AppBar
        position="fixed"
        elevation={0}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        // color="primary"
      >
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          {/* <a href="/" title={siteTitle}><img src={logo} alt={siteTitle} title={siteTitle} /></a> */}
          {/* <Typography variant="h6" color="inherit">
            {siteTitle}
          </Typography> */}
          <Link to='/'>
            <img src={logo} alt={siteTitle} height="30" width="92" className={classes.logo} />
          </Link>
          <div className={classes.grow} />
          <div>
            <IconButton
              arial-label="Display Station List"
              color="inherit"
            >
              <TrainIcon />
            </IconButton>
            <IconButton arial-label="Language List" color="inherit">
              <LanguageIcon />
            </IconButton>
            <IconButton
              arial-label="More Menu Items"
              arial-controls="menu-more-appbar"
              arial-haspopup="true"
              onClick={handleMenu}
              edge="end"
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
            <Menu
              id="menu-more-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={openMenu}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>Menu 1</MenuItem>
              <MenuItem onClick={handleClose}>Menu 2</MenuItem>
            </Menu>

            
          </div>
        </Toolbar>
      </AppBar>
      </HideOnScroll>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          {/* <Typography variant="h6" color="inherit">MENU</Typography> */}
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem button>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText>
              <Link to="/">Home</Link>
            </ListItemText>
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <ListIcon />
            </ListItemIcon>
            <ListItemText>
              <Link to="/components">Components</Link>
            </ListItemText>
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <TrainIcon />
            </ListItemIcon>
            <ListItemText>
              <Link to="/station/">Stations</Link>
            </ListItemText>
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <TrainIcon />
            </ListItemIcon>
            <ListItemText>
              <Link to="/station/takao/">高尾駅</Link>
            </ListItemText>
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <TrainIcon />
            </ListItemIcon>
            <ListItemText>
              <Link to="/station/otsuki/">大月駅</Link>
            </ListItemText>
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <TrainIcon />
            </ListItemIcon>
            <ListItemText>
              <Link to="/station/katsunuma/">勝沼ぶどう郷駅</Link>
            </ListItemText>
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <TrainIcon />
            </ListItemIcon>
            <ListItemText>
              <Link to="/station/enzan/">塩山駅</Link>
            </ListItemText>
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <TrainIcon />
            </ListItemIcon>
            <ListItemText>
              <Link to="/station/yamanashishi/">山梨市駅</Link>
            </ListItemText>
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <TrainIcon />
            </ListItemIcon>
            <ListItemText>
              <Link to="/station/isawaonsen/">石和温泉駅</Link>
            </ListItemText>
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <TrainIcon />
            </ListItemIcon>
            <ListItemText>
              <Link to="/station/kofu/">甲府駅</Link>
            </ListItemText>
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <TrainIcon />
            </ListItemIcon>
            <ListItemText>
              <Link to="/station/obuchisawa/">小淵沢駅</Link>
            </ListItemText>
          </ListItem>
        </List>
      </Drawer>
      <ScrollTop {...props}>
        <Fab color="palette.primary.main" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
}

export default Header
