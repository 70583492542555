import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { mergeClasses } from '@material-ui/styles';

const useStyles = makeStyles(theme =>({
    root: {
        padding: '0 30px',
    },
}));

const Paragraphs = ({paragraphs}, i) => {
    const classes = useStyles();
    let output = []
    // if (paragraphs !== null) {
    if (paragraphs) {
        // console.log(index+': '+paragraphs+': '+num)
        // console.log(paragraphs)
        paragraphs.forEach(paragraph => {
            output = output+'<p>'+paragraph+'</p>'
        })
        return (<div className={classes.root} key={i} dangerouslySetInnerHTML={{__html: output}}></div>)
    } else {
        return null
    }
}

Paragraphs.propTypes = {
    paragraphs: PropTypes.array,
}

export default Paragraphs